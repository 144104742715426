import Countries from './country.json';

export const getCountriesData = () => {
    const countries = [];
    Countries.forEach((country) => {
        countries.push({ "name": country.name, "id": country.iso2 });
    });
    return countries;
};

export const getStatesById = (iso2) => {
    const states = [];
    Countries.forEach((country) => {
        if (country?.iso2 === iso2) {
            country?.states?.forEach((state) => {
                states.push({ "name": state.name, "id": state.state_code });
            })
        }
    });
    return states;
};
